
html {
  font-size: 0.833vw;
  overflow-x: hidden;
  overflow-y: auto;
  color: #14212C;
  width: 100%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
iframe,
pre,
dl,
dt,
dd,
blockquote,
figure,
aside,
fieldset,
legend,
textarea,
hr {
  margin: 0;
  padding: 0;
}

iframe {
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}
td:not([align]),
th:not([align]) {
  text-align: left;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  height: auto;
}
*, *::before, *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img,
video {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

  @font-face {
    font-family: "Poppins";
    font-weight: 200;
    src: url("./fonts/Poppins-Light.ttf") format('truetype');
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: url("./fonts/Poppins-Regular.otf") format('truetype');
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 600;
    src: url("./fonts/Poppins-Black.ttf") format('truetype');
  }


  @font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: italic;
    src: url("./fonts/Poppins-Italic.ttf") format('truetype');
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    src: url("./fonts/Poppins-SemiBold.ttf") format('truetype');
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: italic;
    src: url("./fonts/Poppins-SemiBoldItalic.ttf") format('truetype');
  }

  .slick-prev {
    left: -90px!important;
 }

 .slick-prev:before {
  font-size: 50px !important;
}

 .slick-next {
  right: -50px !important;
}

.slick-next:before {
  font-size: 50px !important;
}
